/* eslint-disable react/no-unknown-property */
import React, { Component } from 'react'
import { connect } from "react-redux";
import { BuddyTabContainer } from '../../PeopleHomeV2/styles';
import { IconWrapper } from '../../ChallengeDetailsV2/styles';
import HoverButton from '../../common/HoverButtonV2';
import PropTypes from "prop-types";
import { ImageUrl } from '../../../utils/constants';
import { HealthInsightsCard, HoverBackground, /*LaunchButton*/ } from './styles';
import { addCompanyInitiatives, fetchCompanyInfo } from '../../../redux/actions';
import SingleCompanyInitiative from '../SingleCompanyInitiative';
import { getCompanyInitiatives, getTopWellnessInitiatives } from '../../../redux/actions/companyDashboard';
import { GetTopWellnessInitiatives } from '../../../redux/constants/apiConstants';
import ThankYouPopup from '../ThankYouPopup';
import Waiting from '../../Waiting';
import DeletePopup from '../DeletePopup';
import { GetInitiativePriority, UpdateCompanyInitiatives } from '../../../redux/constants/apiConstants';
import { toast } from 'react-toastify';
// import { http } from '../../../redux/http';
import moment from 'moment';
import { fetchApi } from '../../../utils/methods';
import DetailPopup from './detailPopup';
import { NewPlusIcon, ViewProfileIcon, CustomizeDashIcon, LaunchEventIcon, TotalInitiativeIcon, EditBackIcon, DeleteUpcomingIcon } from '../../../utils/icons';
import { DownAngle } from '../../SocialFeedsV2/styles';
import { DropdownButton, /*DropdownItem*/ } from 'react-bootstrap';
import { CompanyInitiativeMenuItem } from '../../DropDown/styles';
import CommonButton from '../../common/CommonButton/CommonButton';
import { t } from 'i18next';
import { BASE_URL } from '../../../utils/config';
import { Border } from '../../EventDetails/styles';
import { ButtonContainer } from '../../CreateEvent/styles';
import SkeletonLoder from '../../common/skeletonLoder';

class companyInitiativeNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: this.props.companyInfo.id,
      singleCompanyPopUpState: false,
      thankYouPopupState: false,
      selectedInitiative: {},
      value: "Select Points",
      launchDate:new Date(),
      isUpcoming:0,
      topWellnessInitiatives:[],
      featureNewData: [
        { key: "0 Points", Value: "0 Points", id: '6' },
        { key: "25 Points", Value: "25 Points", id: '1' },
        { key: "50 Points", Value: "50 Points", id: '2' },
        { key: "100 Points", Value: "100 Points", id: '3' },
        { key: "150 Points", Value: "150 Points", id: '4' },
        { key: "200 Points", Value: "200 Points", id: '5' },
        { key: "250 Points", Value: "250 Points", id: '7' },
        { key: "300 Points", Value: "300 Points", id: '8' },
        { key: "350 Points", Value: "350 Points", id: '9' },
        { key: "400 Points", Value: "400 Points", id: '10' },
        { key: "450 Points", Value: "450 Points", id: '11' },
        { key: "500 Points", Value: "500 Points", id: '12' },
        { key: "550 Points", Value: "550 Points", id: '13' },
        { key: "600 Points", Value: "600 Points", id: '14' },
        { key: "650 Points", Value: "650 Points", id: '15' },
        { key: "700 Points", Value: "700 Points", id: '16' },
        { key: "750 Points", Value: "750 Points", id: '17' },
        { key: "800 Points", Value: "800 Points", id: '18' },
        { key: "850 Points", Value: "850 Points", id: '19' },
        { key: "900 Points", Value: "900 Points", id: '20' },
        { key: "950 Points", Value: "950 Points", id: '21' },
        { key: "1000 Points", Value: "1000 Points", id: '22' }
      ],
      pointsArrow : false,
      step:1,
      selectedOption:0,
      calendarStatus : 0,
      isEditId:null,
      isDeletePopup:false,
      isEdit:false,
      showDetailPopup:false,
      onHover: -1,
      priorityInitiatives: {},
      detailInitiative:null,
      loading:false
    }
  }

  componentDidMount(){
    const {getCompanyInitiatives,getTopWellnessInitiatives /*, location */} = this.props;
    const currentYear = new Date().getFullYear();
    // const showAll = location.state.showAll;
    getCompanyInitiatives(this.state.companyId);
    getTopWellnessInitiatives(this.state.companyId,currentYear)
    // window.console.log("showAll",showAll)
    this.fetchPriorityInitiatives(this.state.companyId);
    // this.getTopWellnessInitiatives();
  }


  onHoverMouse = (id) => {
    this.setState({
      onHover: id
    })
  };

  onLeaveHoverMouse = () => {
    this.setState({
      onHover: -1
    })
  };

  
  closeDetailPopup=()=>{
    this.setState({showDetailPopup:false,detailInitiative:null})
    
  }


  schedule = () =>(
    <svg
      width="20px"
      height="auto"
      viewBox="-5 0 25 25"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="icons"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="ui-gambling-website-lined-icnos-casinoshunter"
          transform="translate(-1913.000000, -158.000000)"
          fill="#0D4270"
          fillRule="nonzero"
        >
          <g id="1" transform="translate(1350.000000, 120.000000)">
            <path
              d="M566.453517,38.569249 L577.302459,48.9938158 L577.39261,49.0748802 C577.75534,49.423454 577.968159,49.8870461 578,50.4382227 L577.998135,50.6228229 C577.968159,51.1129539 577.75534,51.576546 577.333675,51.9774469 L577.339095,51.9689832 L566.453517,62.430751 C565.663694,63.1897497 564.399001,63.1897497 563.609178,62.430751 C562.796941,61.650213 562.796941,60.3675924 563.609432,59.5868106 L573.012324,50.5572471 L563.609178,41.4129456 C562.796941,40.6324076 562.796941,39.349787 563.609178,38.569249 C564.399001,37.8102503 565.663694,37.8102503 566.453517,38.569249 Z"
              id="left"
              transform="translate(570.500000, 50.500000) scale(-1, 1) translate(-570.500000, -50.500000) "
            ></path>
          </g>
        </g>
      </g>
    </svg>
  ) 

  close = () => (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_14978_75731"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <rect width={24} height={24} fill="#C4C4C4" fillOpacity={1}/>
      </mask>
      <g mask="url(#mask0_14978_75731)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3605 0.678084C22.6358 -0.0466026 21.4608 -0.0466023 20.7361 0.678084L11.9067 9.50756L3.07795 0.678849C2.4145 0.0154025 1.33884 0.0154032 0.675399 0.678849C0.0119522 1.3423 0.0119521 2.41796 0.675399 3.0814L9.50411 11.9101L0.675642 20.7386C-0.0490441 21.4633 -0.0490441 22.6382 0.675643 23.3629C1.40033 24.0876 2.57528 24.0876 3.29996 23.3629L12.1284 14.5344L20.9577 23.3637C21.6211 24.0271 22.6968 24.0271 23.3602 23.3637C24.0237 22.7002 24.0237 21.6246 23.3602 20.9611L14.531 12.1319L23.3605 3.30241C24.0851 2.57772 24.0851 1.40277 23.3605 0.678084Z"
          fill="#6099b4"
        />
      </g>
    </svg>
  );

  goBack = () => {
    this.props.history.push("/company/dashboard")
  }

  headerView = (initiativesToShow) => (
    <>
      {this.props.isHealthDash && initiativesToShow?.length>0? (
        <>
          <BuddyTabContainer style={{marginTop:"40px",alignItems:"flex-start", borderBottomRightRadius: "0px", borderBottomLeftRadius: "0px", width: "100%", display: 'flex', flexDirection: 'column'}}>
            <p style={{color:'#005c87',fontFamily:'rubik-medium',fontSize:'18px',marginTop:'11px', paddingLeft: '4px', marginBottom: '0px', lineHeight:"24px"}}>Company Initiatives</p>
            <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'0px 0px', padding: '0px', width: "99%"}}/>
          </BuddyTabContainer>
        </>
      ): this.props.isLibrary ?(
        <>
          <BuddyTabContainer style={{marginTop:"0px",alignItems:"flex-start", borderBottomRightRadius: "0px", borderBottomLeftRadius: "0px", width: "100%", display: 'flex', flexDirection: 'column'}}>
            <p style={{color:'#005c87',fontFamily:'rubik-medium',fontSize:'18px',marginTop:'11px', paddingLeft: '4px', marginBottom: '0px', lineHeight:"24px"}}>Company Initiatives</p>
            <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'0px 0px', padding: '0px', width: "99%"}}/>
          </BuddyTabContainer>
        </>
      ): (this.props.location?.state?.showAll&&
        <>
          <BuddyTabContainer marginBottom="25px" style={{marginTop:"25px",alignItems:"center"}}>
            <IconWrapper margin="auto 0px auto 0">
              <HoverButton
                title="Back"
                width={this.props.location?.state?.showAll || this.props.isHealthDash ? "24px" : "20px"}
                height="24px"
                svgPath={this.props.location?.state?.showAll || this.props.isHealthDash ? this.close() : this.schedule()} 
                onClick={() => this.goBack() }
                backgroundColor={"#005C870D"}
                backgroundColorActive={"#005C87"}
              />
            </IconWrapper>
            <p style={{color:'#005c87',fontFamily:'rubik-medium',fontSize:'16px',marginTop:'11px'}}>{this.props.location?.state?.showAll || this.props.isHealthDash ? "Launched Company Initiatives" : "Company Initiatives"}</p>
            <div style={{display:"flex", justifyContent:"flex-end", width:"74%"}}>{this.props.companyInitiatives && this.props.companyInitiatives.length && (this.props.location?.state?.showAll || this.props.isHealthDash) ? (<HoverButton
              title="Add Initiative"
              width="18px"
              height="18px"
              svgPath={NewPlusIcon()}
              fillOpacity={1} backgroundColor={"#f0f6f8"} backgroundColorActive={"#005c87"} activeColor={"#598ea8"} inActiveColor={"white"}
              onClick={()=>this.props.history.push({pathname:`/company/initiative`, state:{showAll:false}})}
            />): null}
            </div>
          </BuddyTabContainer>
        </>
      )}
    </>
  );

  singleCompanyPopUp = (obj) => {
    this.setState({
      companyPopup: false,
      singleCompanyPopUpState: true,
      selectedInitiative: obj
    });
  };

  // To handle the company initiative redirection
  redirectToCompanyInitiative = (obj) => {
    // window.console.log("redirectToCompanyInitiative: ", obj);
    const { history } = this.props;
    history.push({pathname: "/company/company-initiative", state: {id: obj?.id, initiative_name: obj?.initiative_name,initiativeDetails:obj}});
  };

  singleCompanyModal = () => {
    this.setState({
      singleCompanyPopUpState: false, value: "Select Points"
    });
  };

  addCompanyData = () => {
    const { addCompanyInitiatives } = this.props;
    const { selectedInitiative, value, launchDate, isUpcoming } = this.state;
    const year=new Date().getFullYear();
    let obj = {};
    obj['company_id'] = this.state.companyId
    obj['initiative_id'] = selectedInitiative.id
    obj['point_value'] = value;
    obj['launch_date'] = launchDate.toISOString().split('T')[0];
    obj['is_upcoming'] = isUpcoming
    addCompanyInitiatives(obj, this.state.companyId, year);
  }

  handleArrow = () => {
    this.setState({pointsArrow: !this.state.pointsArrow})
  }

  thankYouPopup = () => {

    if(this.state.isEdit){
      this.updateInitiative();
      // this.addCompanyData();
    }else{
      this.setState({
        singleCompanyPopUpState: false,
        thankYouPopupState: true,
      });
      this.addCompanyData();

    }

  };

  thankYouCloseModal = () => {
    this.setState({
      thankYouPopupState: false,
      value: "Select Points"
    });
    this.props.history.push("/company/dashboard")
  };

  onChangeDropdown = (list) => {
    this.setState({ value: list.key, pointsArrow:!this.state.pointsArrow});
    // if (list.key === "0 Points") {
    //   this.setState({ value: "No Points" });
    // }
    // else {
    //   this.setState({ value: list.key });
    // }
  }

  onNext = () => {
    this.setState({step:2})
  }
  onBack = () => {
    this.setState({step:1})
  }


  
  
  getTopWellnessInitiatives = async()=>{
    let apiUrl = GetTopWellnessInitiatives + "/" + this.state.companyId;
    try {
      const res = await fetchApi(apiUrl , "GET");
      if (res.status === "success") {
        this.setState({topWellnessInitiatives: res?.data})

      } else {
        toast.error(res.error);
      }
    } catch (error) {
      window.console.log(error);
    }

  }
  
  toggleRadioButton = (e) => {
    this.setState({ selectedOption: e });
    const today = new Date();
    if (e === 1) {
      // .toISOString().split('T')[0]
      // window.console.log("today",today)
      this.setState({launchDate: today});
    } else if(e === 2) {
      this.setState({launchDate: new Date(this.state.launchDate) || today});
      this.onChange(new Date(this.state.launchDate), e);
    }
  };

  onChange = (value/* ,event */) => {
    this.setState({
      launchDate: value,
      // .toISOString().split('T')[0],
      isUpcoming:1,
      calendarStatus:0
    })
  }

  toggleCalendar = () => {
    this.setState({calendarStatus: 1 });
  }

  editInitiative = (item) => {
    // window.console.log("item",item);
    this.setState({value:item.points,launchDate:item.launch_date,isUpcoming:item.is_upcoming,selectedOption:item.is_upcoming === 0 ? 1 : 2, isEdit:true})
    this.singleCompanyPopUp(item)
  }

  deleteInitiative = (item) => {
    this.setState({selectedInitiative:item,isDeletePopup:true})
  }

  closeDeletePopup = (status) => {
    this.setState({isDeletePopup:status})
  }

  updateInitiative = async()=>{
    // const AuthToken = localStorage.getItem('AUTH_TOKEN');

    const payload = {point_value:this.state.value,launch_date:moment(new Date(this.state.launchDate)).format("YYYY-MM-DD"),company_id:this.state.companyId,initiative_id:this.state.selectedInitiative.id,is_upcoming:this.state.isUpcoming,}

    try {
      const res = await fetchApi(`${UpdateCompanyInitiatives}`, "PUT",payload, {},this.successFunc);
      if (res.message) {
        toast.error(res.message);
      }
      
    }catch(error){
      toast.error(error.message);
      // dispatch(networkError(error.message));
    }
    
  }


  successFunc = ()=>{
    if(this.state.isDeletePopup){
      const currentYear = new Date().getFullYear();
      this.props.getCompanyInitiatives(this.state.companyId);
      this.props.getTopWellnessInitiatives(this.state.companyId,currentYear)

    }else{
      this.setState({
        singleCompanyPopUpState: false,
        step:1,
        isEditId:null
      },()=>{      const currentYear = new Date().getFullYear();
        this.props.getCompanyInitiatives(this.state.companyId);
        this.props.getTopWellnessInitiatives(this.state.companyId,currentYear)});
      toast.success("Initiative Updated Successfully");

    }

    
  }


  fetchPriorityInitiatives = async(companyId)=>{
    this.setState({ loading: true });
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    let url =
    BASE_URL + GetInitiativePriority + "/" + companyId;
    await fetch(url, { method: "GET", headers: { AUTHTOKEN: AuthToken } }).then(
      (res) => {
        res.json().then((data) => {
          window.console.log("priority res: ", data.data);
          this.setState({
            priorityInitiatives: data.data,loading:false
          })
        }).catch((error)=>{
          toast.error(error.message);
          this.setState({
            loading:false
          })
        });
      }
    ).catch((error)=>{
      toast.error(error.message);
      this.setState({
        loading:false
      })
    });
  };

  render() {
    const {companyInitiatives,wellnessInitiatives, isLibrary, role} = this.props;
    const{showDetailPopup,detailInitiative, onHover, priorityInitiatives,/*topWellnessInitiatives,*/loading} = this.state;

    const filteredWellnessInitiatives = wellnessInitiatives?.filter((item) => {
      const isPriority = priorityInitiatives?.initiatives?.some((p) => p.id === item.id);
      const isUpcoming = item.is_upcoming;
      return !(isPriority || isUpcoming);
    });
    // window.console.log("topWellnessInitiatives",topWellnessInitiatives)
    // window.console.log("companyInitiatives",companyInitiatives)
  
    const initiativesToShow = this.props.isHealthDash ? filteredWellnessInitiatives : wellnessInitiatives;

    if(companyInitiatives === null || wellnessInitiatives === null){
      <Waiting/>
    }
    let showUpcoming=0;
    wellnessInitiatives?.length && wellnessInitiatives?.map((data)=>{
      if(data.is_upcoming){
        showUpcoming=showUpcoming+1;
      }
    })
    if (loading) {
      return <div style={{width:'385px'}}> 
        <SkeletonLoder width={"1248px"} height={"180px"}/>
        <SkeletonLoder width={"1248px"} height={"260px"} style={{marginTop:"40px"}}/>
      </div>;
    }
    return (
      <div style={{marginTop:"20px"}}>
        {/* Need to add condition on top 3 selction basis*/}
        { priorityInitiatives && priorityInitiatives?.initiatives?.length > 0 && this.props.isHealthDash ? (
      
          <>
            <div style={{ display:"flex",rowGap:"15px",columnGap:"32px" }}>
              <div style={{display:"flex",rowGap:"15px",columnGap:"32px"}} >
                <div style={{width:"288px",height:"180px",padding:'12px',borderRadius:'4px',position:'relative', background: '#FFFFFF' }}>
                  <div style={{width:'250px',display:'flex',alignItems:'center',gap:"15px", marginBottom: "20px"}}>
                    {TotalInitiativeIcon()}
                    <div>
                      <p style={{width:'185px',color:"#005c87",fontFamily:'rubik-medium',fontSize:'18px',marginBottom:0}}>Initiatives Participation</p>
                    </div>
                  </div>
                  <div>
                    <p style={{ display: 'flex', alignItems: 'center', fontFamily: 'Rubik', fontSize: '14px', lineHeight: '20px', color: 'rgba(0, 92, 135, 0.6)' }}><span style={{ fontFamily: "Rubik-Medium", fontSize: "24px", lineHeight: '24px', color: "#85C0EA", marginRight: '15px' }}>{priorityInitiatives?.total_participants}</span> Total Participants</p>
                  </div>
                  <Border style={{background:'rgba(0, 92, 135, 0.1)',margin:'0px',width:"100%",marginTop:"20px", marginBottom: "20px"}}/>
                  <div>
                    <p style={{ display: 'flex', alignItems: 'center', fontFamily: 'Rubik', fontSize: '14px', lineHeight: '20px', color: 'rgba(0, 92, 135, 0.6)' }}><span style={{ color: "#85C0EA",fontFamily: "Rubik-Medium", marginRight: '4px' }}>{parseFloat(priorityInitiatives?.participants_percentage).toFixed(priorityInitiatives?.participants_percentage % 1 === 0 ? 0 : 2)}% Users</span>participated</p>
                  </div>
                </div>
              
              </div>
              {(this.props.location?.state?.showAll || this.props.isHealthDash) ? 
                <div style={{display:"flex",rowGap:"15px",columnGap:"32px"}} >
                  {priorityInitiatives !== null && priorityInitiatives?.initiatives?.length > 0 ? priorityInitiatives?.initiatives?.map((item,index) => <div key={index} style={{width:"288px",height:"180px",padding:'12px',borderRadius:'4px',position:'relative', background: '#FFFFFF' }} onMouseEnter={() => this.onHoverMouse(item?.id)} onMouseLeave={() => this.onLeaveHoverMouse()}>
                    {item && !item?.is_upcoming && item?.is_launch === 1 && onHover === item?.id &&
                      <HoverBackground>
                        <div className='view-button'
                          onClick={()=>this.setState({showDetailPopup:true,detailInitiative:item})}
                          onMouseEnter={(e) => { e.target.style.background = '#005C87'; e.target.style.color = '#ffff'; }}
                          onMouseLeave={(e) => { e.target.style.background = '#007AB1'; e.target.style.color = 'white'; }}>
                          <div style={{ marginRight: '10px' }}><ViewProfileIcon/></div>{("View")}
                        </div>
                      </HoverBackground>
                    }
                    {/* {item && item.is_upcoming && (new Date(item.launch_date) > new Date()) && onHover === item?.id &&
                      <HoverBackground>
                        <div className='view-button'
                          onClick={() => this.editInitiative(item)}
                          onMouseEnter={(e) => { e.target.style.background = '#005C87'; e.target.style.color = '#ffff'; }}
                          onMouseLeave={(e) => { e.target.style.background = '#007AB1'; e.target.style.color = 'white'; }}>
                          <div style={{ marginRight: '10px' }}><EditBackIcon/></div>{("Edit")}
                        </div>
                        <div className='view-button'
                          style={{ width: "48px", minWidth: '48px', height: "48px", marginLeft: '10px' }}
                          onClick={() => this.deleteInitiative(item)}
                          onMouseEnter={(e) => { e.target.style.background = '#005C87'; e.target.style.color = '#ffff'; }}
                          onMouseLeave={(e) => { e.target.style.background = '#007AB1'; e.target.style.color = 'white'; }}>
                          <div><DeleteUpcomingIcon/></div>
                        </div>
                      </HoverBackground>
                    } */}
                    <div style={{width:'100%',display:'flex',alignItems:'center',gap:"15px", marginBottom: "20px", height:"54px"}}>
                      <img src={ImageUrl+"/"+item.initiative_image} alt="" style={{width:"48px",height:"48px",borderRadius:"6px"}}/>
                      <div>
                        <p style={{width:'185px',color:"#005c87",fontFamily:'rubik-medium',fontSize:'18px',marginBottom:0}}>{item.initiative_name}</p>
                      </div>
                    </div>
                    {item?.is_launch === 1 ? (<>
                      <div>
                        <p style={{ display: 'flex', alignItems: 'center', fontFamily: 'Rubik', fontSize: '14px', lineHeight: '20px', color: 'rgba(0, 92, 135, 0.6)' }}><span style={{ fontFamily: "Rubik-Medium", fontSize: "24px", lineHeight: '24px', color: item.font_color, marginRight: '15px' }}>{item.initiative_total}</span> Total Participants</p>
                      </div>
                      <Border style={{background:'rgba(0, 92, 135, 0.1)',margin:'0px',width:"100%",marginTop:"20px", marginBottom: "20px"}}/>
                      <div>
                        <p style={{ display: 'flex', alignItems: 'center', fontFamily: 'Rubik', fontSize: '14px', lineHeight: '20px', color: 'rgba(0, 92, 135, 0.6)' }}><span style={{ color: item.font_color,fontFamily: "Rubik-Medium", marginRight: '4px' }}>{item.initiative_percent}% Users</span>taken initiative</p>
                      </div>
                    </>): (
                      <>
                        <ButtonContainer style={{marginTop: '35px'}}>
                          {/* <div className="wrapper"> */}
                          <CommonButton
                            btnType={"squareIcon"}
                            styles={{color:"#007AB1", marginAuto:'auto', fontSize: '16px', width: '264px', height: '48px', borderRadius: '4px', marginTop: '40px', marginLeft:"0px !important"}}
                            onClick={() => this.redirectToCompanyInitiative(item)}
                            title={t("Launch Initiative")}
                            icon={LaunchEventIcon()}
                          />
                          {/* </div> */}
                        </ButtonContainer>
                      </>
                    )}
                    
                  </div>) : null
                  }
                </div>
                : 
                null}
            </div>
          </>
        ) : (
          <>
            {priorityInitiatives && priorityInitiatives?.initiatives?.length === 0 && this.props.location?.state?.showAll || this.props.isHealthDash && (
              <div style={{width:'1248px',background:'white',height:'auto',margin:"auto",marginBottom:"50px",display:"flex",flexWrap:'wrap',rowGap:"15px",columnGap:"25px", borderRadius:"6px"}}>
                <div style={{ display: 'flex'}}>
                  <div style={{padding:'20px 0px 20px 24px'}}>
                    <div><p style={{color:'#005c87',fontFamily:'rubik-medium',fontSize:'18px', marginBottom:"12px", lineHeight:"24px"}}>Customize your dashboard.</p></div>
                    <div>
                      <p style={{color:'#005c87',fontFamily:'rubik',fontSize:'16px', lineHeight:"20px"}}>
                        Set up your preferences to ensure your dashboard is tailored to meet your specific health data management needs, providing you with a seamless and personalized experience.
                      </p>
                    </div>
                    <div>
                      <CommonButton
                        btnType={"squareIcon"}
                        styles={{color:"#007AB1", marginAuto:'auto', border: '#007AB1', fontSize: '16px', float: 'unset', width:"220px"}}
                        onClick={() => this.props.history.push("/company/initiative-priority")}
                        title={t("Let’s Get Started")}
                        icon={LaunchEventIcon()}
                      />
                    </div>
                  </div>
                  <div>
                    <div style={{ margin: "0px 100px" }}>
                      {CustomizeDashIcon()}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )
        }

        {/* {priorityInitiatives && priorityInitiatives?.initiatives?.length > 0 && <> */}
        {priorityInitiatives && ((priorityInitiatives?.initiatives?.length > 0 || !this.props.isHealthDash) && (<>
          {this.headerView(initiativesToShow)}
          {(this.props.location?.state?.showAll || this.props.isHealthDash) ? 
            <div style={{width:'1248px',background:'white',height:'auto',margin:"auto",marginBottom:"25px",padding:initiativesToShow?.length > 0?'12px':"0",display:"flex",flexWrap:'wrap',gap: "12px", borderRadius:"0 0 6px 6px"}} >
              {/* {wellnessInitiatives !== null && wellnessInitiatives.length > 0 ? wellnessInitiatives.filter((item) => !priorityInitiatives?.initiatives?.some((p) => p.id === item.id)&& !item.is_upcoming).map((item,index) => <div key={index} style={{width:"382px",height:"100px",display:'flex',alignItems:'center',justifyContent:'space-between',padding:'15px',borderRadius:'6px',border:"1px solid #b3cfdb",position:'relative',cursor:"pointer" }} onMouseEnter={() => this.onHoverMouse(item?.id)} onMouseLeave={() => this.onLeaveHoverMouse()} > */}
              {initiativesToShow?.length > 0 && initiativesToShow.map((item,index) => <div key={index} style={{width:"400px",height:"84px",display:'flex',alignItems:'center',justifyContent:'space-between',padding:'12px',borderRadius:'6px',border:"1px solid rgba(0, 122, 177, 0.3)",position:'relative',cursor:"pointer" }} onMouseEnter={() => this.onHoverMouse(item?.id)} onMouseLeave={() => this.onLeaveHoverMouse()} >
                {item && !item?.is_upcoming && onHover === item?.id &&
                    <HoverBackground>
                      <div className='view-button'
                        onClick={()=>this.setState({showDetailPopup:true,detailInitiative:item})}
                        onMouseEnter={(e) => { e.target.style.background = '#005C87'; e.target.style.color = '#ffff'; }}
                        onMouseLeave={(e) => { e.target.style.background = '#007AB1'; e.target.style.color = 'white'; }}>
                        <div style={{ marginRight: '10px' }}><ViewProfileIcon/></div>{("View")}
                      </div>
                    </HoverBackground>
                }
                <div style={{width:'100%',display:'flex',alignItems:'center',gap:"15px"}} /* onClick={()=>this.setState({showDetailPopup:true,detailInitiative:item})} */>
                  <img src={ImageUrl+"/"+item.initiative_image} alt="" style={{width:"60px",height:"60px",borderRadius:"6px"}}/>
                  <div style={{width:"240px", display:"flex", flexWrap:"wrap", height:"60px"}}>
                    <p style={{width:'100%',display:"flex",alignItems:"center",color:"#005c87",fontFamily:'rubik-medium',fontSize:'16px',marginBottom:0, textWrap: 'nowrap', textOverflow: "ellipsis", overflow: "hidden", lineHeight:"20px" }}>{item.initiative_name}</p>
                    <p style={{width:'100%',display:"flex",alignItems:"center", color:"rgba(0, 92, 135, 0.6)",fontFamily:'rubik',fontSize:'16px',marginBottom:0, lineHeight:"20px"}}>{(item.is_upcoming && (new Date(item.launch_date) > new Date())) ? "Upcoming" : "Active"} {(!item.is_upcoming || (new Date(item.launch_date) <= new Date()) )  && "| " +(item.points?"+":"") +item.points + " pts"}  {(item.is_upcoming && (new Date(item.launch_date) > new Date())) ? "| " + (Math.ceil((Number(new Date(item.launch_date) - new Date())/ (1000 * 60 * 60 * 24))) + " Days to start") : ""}</p>
                  </div>
                </div>
                {(item.is_upcoming && (new Date(item.launch_date) > new Date())) ?  <DownAngle >
                  <DropdownButton id="buddy" title={<div style={{width:"40px",height:"40px",background:"rgb(240, 246, 248)",display:"flex",alignItems:"center",justifyContent:"center",borderRadius:"6px"}}><img src='/public/images/menuIcon.png' style={{width:"24px", height:"auto"}}/></div>}>
                    <CompanyInitiativeMenuItem onClick={() => this.editInitiative(item)} className='company-initiative'>
                      {"Edit"}
                    </CompanyInitiativeMenuItem>
                    <CompanyInitiativeMenuItem onClick={() => this.deleteInitiative(item)} className='company-initiative'>
                      {"Delete"}
                    </CompanyInitiativeMenuItem>
                  </DropdownButton>
                </DownAngle>: <div style={{/* width:'72px', */height:'38px',borderRadius:'6px',background: this.props.isHealthDash ? 'unset' : '#f2f7f9',color:'#005c87',fontFamily:'rubik-medium',fontSize:'24px',lineHeight: "24px",display:'flex',alignItems:'center',justifyContent:'center', border: this.props.isHealthDash ? '' : ''}}>{item.percentage}%</div>}
                {/* {item.is_upcoming ? <div onClick={() => this.setState({isEditId:index})} style={{width:'40px',height:'40px',background:"#f0f6f8",borderRadius:'6px',cursor:'pointer',display:'flex',alignItems:'center',justifyContent:'center'}}><img src={ImageUrl+"/threeDotIcon.svg"} alt="menu" /></div> :<div style={{width:'72px',height:'38px',borderRadius:'6px',background:'#f2f7f9',color:'#005c87',fontFamily:'rubik-medium',fontSize:'16px',display:'flex',alignItems:'center',justifyContent:'center'}}>{item.percentage}%</div>}
                {this.state.isEditId === index && <div style={{position:'absolute',top:'74px',right:'15px',zIndex:1,width:'95px',height:'80px',padding:'10px',display:'flex',flexDirection:'column',alignItems:'center',border:'1px solid #e6eff3',background:'white'}}>
                  <p onClick={() => this.editInitiative(item)} style={{color:'#005c87',fontFamily:"rubik-medium",fontSize:'12px',cursor:"pointer"}}>Edit</p>
                  <p style={{width:'100%',border:'1px solid #e6eff3'}}></p>
                  <p onClick={() => this.deleteInitiative(item)} style={{color:'#005c87',fontFamily:"rubik-medium",fontSize:'12px',cursor:"pointer"}}>Delete</p>
                </div>} */}
              </div>)
              }
            </div>
            : 
            (companyInitiatives?.length > 0 || wellnessInitiatives?.length > 0) && (
              <div style={{width:'1248px',background:'white',height:'auto',margin:"auto",padding:'12px',display:"flex",flexWrap:'wrap',gap: "12px", borderRadius:"0 0 6px 6px"}}>
                {companyInitiatives?.length > 0 &&
                     companyInitiatives.map((item, index) => (
                       <div key={index} style={{width:"400px",height:"84px",display:'flex',alignItems:'center',justifyContent:'space-between',padding:'12px',borderRadius:'6px',border:"1px solid rgba(0, 122, 177, 0.3)",position:'relative',cursor:"pointer" }} onMouseEnter={() => this.onHoverMouse(item?.id)} onMouseLeave={() => this.onLeaveHoverMouse()}>
                         {item && onHover === item?.id &&
                           <HoverBackground>
                             <div className='view-button'
                               onClick={!item?.re_launch_status?() => this.setState({showDetailPopup:true,detailInitiative:item}):() => this.redirectToCompanyInitiative(item)}
                               onMouseEnter={(e) => { e.target.style.background = '#005C87'; e.target.style.color = '#ffff'; }}
                               onMouseLeave={(e) => { e.target.style.background = '#007AB1'; e.target.style.color = 'white'; }}>
                               <div style={{ marginRight: '10px' }}>{item?.re_launch_status?<LaunchEventIcon/>:<ViewProfileIcon/>}</div>{!item?.re_launch_status?"View":("Launch")}
                             </div>
                           </HoverBackground>
                         }
                         <div style={{width:'100%',display:'flex',alignItems:'center',gap:"15px"}}>
                           <img src={ImageUrl+"/"+item.initiative_image} alt="" style={{width:"60px",height:"60px",borderRadius:"6px"}}/>
                           <p style={{color:"#005c87",fontFamily:'rubik-medium',fontSize:'16px',marginBottom:0, textWrap: 'nowrap', textOverflow: "ellipsis", overflow: "hidden", lineHeight:"20px"}}>{item.initiative_name}</p>
                         </div>
                       </div>
                     ))
                }
                   
                {(wellnessInitiatives?.length > 0) ?
                  wellnessInitiatives.map((item, index) => (
                    <div key={index} style={{width:"400px",height:"84px",display:'flex',alignItems:'center',justifyContent:'space-between',padding:'12px',borderRadius:'6px',border:"1px solid rgba(0, 122, 177, 0.3)",position:'relative',cursor:"pointer" }} onMouseEnter={() => this.onHoverMouse(item?.id)} onMouseLeave={() => this.onLeaveHoverMouse()}>
                      {item && onHover === item?.id &&
                           <HoverBackground>
                             <div className='view-button'
                               onClick={() => this.setState({showDetailPopup:true,detailInitiative:item})}
                               onMouseEnter={(e) => { e.target.style.background = '#005C87'; e.target.style.color = '#ffff'; }}
                               onMouseLeave={(e) => { e.target.style.background = '#007AB1'; e.target.style.color = 'white'; }}>
                               <div style={{ marginRight: '10px' }}><ViewProfileIcon/></div>{("View")}
                             </div>
                           </HoverBackground>
                      }
                      <div style={{width:'100%',display:'flex',alignItems:'center',gap:"15px"}}>
                        <img src={ImageUrl+"/"+item.initiative_image} alt="" style={{width:"60px",height:"60px",borderRadius:"6px"}}/>
                        <p style={{color:"#005c87",fontFamily:'rubik-medium',fontSize:'16px',marginBottom:0, textWrap: 'nowrap', textOverflow: "ellipsis", overflow: "hidden", lineHeight:"20px"}}>{item.initiative_name}</p>
                      </div>
                    </div>
                  )):null
                }
              </div>
            )}

         
          {this.props.isHealthDash && showUpcoming>0 && <> <BuddyTabContainer style={{marginTop:"40px",alignItems:"flex-start", borderBottomRightRadius: "0px", borderBottomLeftRadius: "0px", width: "100%", display: 'flex', flexDirection: 'column'}}>
            <p style={{color:'#005c87',fontFamily:'rubik-medium',fontSize:'18px',marginTop:'11px', paddingLeft: '4px', marginBottom: '0px'}}>Upcoming Initiatives</p>
            <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'0px 0px', width: "99%"}}/>
          </BuddyTabContainer> 
          </>}
          {(this.props.isHealthDash && wellnessInitiatives?.length > 0) ? 
            <div style={{width:'1248px',background:'white',height:'auto',margin:"auto",padding:showUpcoming > 0?'12px':"0",display:"flex",flexWrap:'wrap',gap: "12px", borderRadius:"0 0 6px 6px"}} >
              {wellnessInitiatives !== null && wellnessInitiatives.length > 0 ? wellnessInitiatives.filter((item) => item.is_upcoming).filter((item) => !priorityInitiatives?.initiatives?.some((p) => p.id === item.id)).map((item,index) => <div key={index} style={{width:"400px",height:"84px",display:'flex',alignItems:'center',justifyContent:'space-between',padding:'12px',borderRadius:'6px',border:"1px solid rgba(0, 122, 177, 0.3)",position:'relative',cursor:"pointer" }} onMouseEnter={() => this.onHoverMouse(item?.id)} onMouseLeave={() => this.onLeaveHoverMouse()}>
                {item && item.is_upcoming && (new Date(item.launch_date) > new Date()) && onHover === item?.id &&
                    <HoverBackground>
                      <div className='view-button'
                        onClick={() => this.editInitiative(item)}
                        onMouseEnter={(e) => { e.target.style.background = '#005C87'; e.target.style.color = '#ffff'; }}
                        onMouseLeave={(e) => { e.target.style.background = '#007AB1'; e.target.style.color = 'white'; }}>
                        <div style={{ marginRight: '10px' }}><EditBackIcon/></div>{("Edit")}
                      </div>
                      <div className='view-button'
                        style={{ width: "48px", minWidth: '48px', height: "48px", marginLeft: '10px' }}
                        onClick={() => this.deleteInitiative(item)}
                        onMouseEnter={(e) => { e.target.style.background = '#005C87'; e.target.style.color = '#ffff'; }}
                        onMouseLeave={(e) => { e.target.style.background = '#007AB1'; e.target.style.color = 'white'; }}>
                        <div><DeleteUpcomingIcon/></div>
                      </div>
                    </HoverBackground>
                }   
                <div style={{width:'100%',display:'flex',alignItems:'center',gap:"15px"}} /* onClick={()=>this.setState({showDetailPopup:true,detailInitiative:item})} */>
                  <img src={ImageUrl+"/"+item.initiative_image} alt="" style={{width:"60px",height:"60px",borderRadius:"6px"}}/>
                  <div style={{width:"100%", display:"flex", flexWrap:"wrap", height:"60px"}}>
                    <p style={{width:'100%',color:"#005c87",fontFamily:'rubik-medium',fontSize:'16px', lineHeight:"20px", marginBottom:0, textWrap: 'nowrap', textOverflow: "ellipsis", overflow: "hidden", display:"flex", alignItems:"center"}}>{item.initiative_name}</p>
                    <p style={{width:'100%',color:"rgba(0, 92, 135, 0.6)",fontFamily:'rubik',fontSize:'16px',marginBottom:0, display:"flex", alignItems:"center"}}>{(item.is_upcoming && (new Date(item.launch_date) > new Date())) ? "Upcoming" : "Active"} {(!item.is_upcoming || (new Date(item.launch_date) <= new Date()) )  && "| " +  (item.points?"+":"") +item.points + " pts"}  {(item.is_upcoming && (new Date(item.launch_date) > new Date())) ? "| " + (Math.ceil((Number(new Date(item.launch_date) - new Date())/ (1000 * 60 * 60 * 24))) + " Days to start") : ""}</p>
                  </div>
                </div>
                 
              </div>) : null
              }
            </div>
            : 
            null}
        </>))}  
        {showDetailPopup && <DetailPopup showModal={showDetailPopup} detailInitiative={detailInitiative} onClose={this.closeDetailPopup} companyId={this.props.companyId}/>}
        {/* </>} */}
        {this.state.singleCompanyPopUpState && 
        <SingleCompanyInitiative 
          selectedInitiative={this.state.selectedInitiative} 
          singleCompanyPopUp={this.thankYouPopup} 
          showModal={this.state.singleCompanyPopUpState} 
          onClose={this.singleCompanyModal} 
          featureNewData={this.state.featureNewData} 
          handleArrow={this.handleArrow} 
          arrowStatus={this.state.pointsArrow} 
          onChangeDropdown={this.onChangeDropdown} 
          value={this.state.value} 
          step={this.state.step}
          launchDate={new Date(this.state.launchDate)}
          status={this.state.isUpcoming}
          onNext={this.onNext}
          onBack={this.onBack}
          toggleRadioButton={this.toggleRadioButton}
          selectedOption={this.state.selectedOption}
          onChange={this.onChange}
          calendarStatus={this.state.calendarStatus}
          toggleCalendar = {this.toggleCalendar}
          isEdit={this.state.isEdit}
        />
        }
        {this.state.thankYouPopupState && <ThankYouPopup selectedInitiative={this.state.selectedInitiative} thankYouPopup={this.thankYouPopup} showModal={this.state.thankYouPopupState} value={this.state.value} history={this.props.history} onClose={this.thankYouCloseModal} />}
        {this.state.isDeletePopup && <DeletePopup showModal={this.state.isDeletePopup} selectedInitiative={this.state.selectedInitiative} history={this.props.history} companyId={this.state.companyId} onClose={this.closeDeletePopup} successFunc={this.successFunc}/> }
        {showDetailPopup && <DetailPopup showModal={showDetailPopup} detailInitiative={detailInitiative} onClose={this.closeDetailPopup} companyId={this.props.companyInfo.id} history={this.props.history} setPage={this.props.setPage}/>}

        {!isLibrary && role === "ADMIN" && this.props.history.location.pathname.includes("/company/health") ?<HealthInsightsCard>
          <div className='text-container'>
            <div className='title-container'><span className='title'>Discover Health Insights with Our Professional Coaches</span></div>
            <div className='btn-container'><span className='start-btn' onClick={()=>this.props.history.push("/company/program")}>Let’s Get Started</span></div>
          </div>
        </HealthInsightsCard> : null}
      </div>
    )
  }
}

companyInitiativeNew.propTypes = {
  history: PropTypes.object,
  wellnessInitiatives: PropTypes.array,
  companyInitiatives: PropTypes.array,
  addCompanyInitiatives: PropTypes.func,
  getTopWellnessInitiatives:PropTypes.func,
  companyId:PropTypes.any,
  companyInfo : PropTypes.any,
  getCompanyInitiatives:PropTypes.func,
  location:PropTypes.object,
  isHealthDash: PropTypes.bool,
  isLibrary: PropTypes.bool,
  setPage:PropTypes.func,
  role:PropTypes.string
};

const mapStateToProps = (state) => ({
  wellnessInitiatives: state.companyDashboard.wellnessInitiatives,
  companyInitiatives: state.companyDashboard.companyInitiatives,
});

const mapDispatchToProps = (dispatch) => ({
  getCompanyInitiatives: (companyId) => dispatch(getCompanyInitiatives(companyId)),
  getTopWellnessInitiatives : (companyId,year) => dispatch(getTopWellnessInitiatives(companyId,year)),
  addCompanyInitiatives: (data, companyId, year) => dispatch(addCompanyInitiatives(data, companyId, year)),
  fetchCompanyInfo: (companyId) => dispatch(fetchCompanyInfo(companyId)),
});
  

export default connect(mapStateToProps,mapDispatchToProps)(companyInitiativeNew)